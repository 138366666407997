import E503 from "../../components/error-pages/E503"
import Header from "../../components/error-pages/errorheader"
import Layout from "../../components/layout"
import React from "react"

const E503Page = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  
  return (
    <Layout>
      <Header />
      <E503 staticContent={staticContent} staticImages={staticImages}/>
    </Layout>
  )
}

export default E503Page;